import { Col, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import MenuNav from "./MenuNav";
import logo from "../../../assets/images/logo.png";
import earth from "../../../assets/images/earth.png";
import styles from "./style.module.scss";
import i18next from "i18next";
import { getSections } from "../../../store/Website/actions/home";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch()
  const [state, setState] = useState(false);
  const showDrawer = () => {
    setState(!state);
  };
  const onClose = () => {
    setState(false);
  };

  const [currentLang , setCurrentLang] = useState();

    function handleClick(lang) {
      i18next.changeLanguage(lang)
      if(localStorage.getItem('i18nextLng') === 'en'){
        document.documentElement.setAttribute("dir", 'ltr');
        setCurrentLang('en')
      }else{
        document.documentElement.setAttribute("dir", 'rtl');
        setCurrentLang('ar')
      }
    }

    useEffect(() => {
      if(localStorage.getItem('i18nextLng') === 'en-US' || localStorage.getItem('i18nextLng') === 'en'){
        i18next.changeLanguage("ar");
        localStorage.setItem("i18nextLng","ar")
        document.documentElement.setAttribute("dir", 'rtl');
        setCurrentLang('ar')
      }else{
        document.documentElement.setAttribute("dir", 'ltr');
        i18next.changeLanguage("en");
        setCurrentLang('en')
      }
    }, []);



    

  useEffect(() => {
    if(currentLang){
      console.log("🚀 ~ file: index.jsx:52 ~ useEffect ~ currentLang:", currentLang)
      dispatch(getSections({
        params:{
          language:currentLang
        }
      }));
    }
  }, [dispatch , currentLang]);


  return (
    <div className={`${styles["main-header"]}`}>
      <div className="container">
        <Row align="middle" justify="space-between">
          <Col span={3}>
            <Link to="/admin/blogs"><img src={logo} alt="" className={`${styles["logo"]}`} /></Link>
          </Col>
          <Col span={16} className={`${styles["menu"]}`}>
            <MenuNav />
          </Col>
          <Col className={`${styles["flex-auto"]}`}>
          {currentLang === 'ar' ? <button className={`${styles["btn-lang"]}`} onClick={()=>handleClick('en')} value="en" ><img src={earth} className="ml-6" alt="" /> English</button>  : <button  className={`${styles["btn-lang"]}`} onClick={()=>handleClick('ar')} value="ar"><img src={earth} className="ml-6" alt="" /> العربية</button>}
          </Col>
          <Col span={3} className={`${styles["menu-mobile"]}`}>
            <div className="menuCon">
              <div className="barsMenu" onClick={showDrawer}>
                <span className={`${styles["barsBtn"]}`}></span>
              </div>
              <Drawer
              width={250}
                title={false}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={state}
              >
                <div onClick={showDrawer} className={`${styles["close"]}`}></div>
                <div className="mt-30">
                  <MenuNav />
                </div>
              </Drawer>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
