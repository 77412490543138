import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import { useSelector } from "react-redux";
import styles from './style.module.scss';

const Footer = () => {

  const [dataFooter, setDataFooter] = useState();

  const {dataSections } = useSelector((state) => state.sections);


  useEffect(() => {
    dataSections?.sections?.filter(function (item) {
      if (item.name === "footer") {
        setDataFooter(item);
      }
    });

  }, [dataSections]);
  return (
    <div className={`${styles["main-footer"]}`}>
      <div className="container">
        <Row justify="space-between" align="middle">
          <Col lg={6}>
            <h5 className="text-white mb-20">{dataFooter?.subtitle}</h5>
            <ul className={`d-flex align-items-center ${styles["social-media"]}`}>
              {dataSections?.socials.map((item,index)=>(
                <li key={index}>
                  <a href={item.url} target="_blank"><img src={item.image} alt={item.name} /></a>
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={6}>
            <ul>
              {dataFooter?.items?.map((item,index)=>(
                <li key={index}>
                  {item.name ==='phone' ? <a className={`text-white ${styles["footer-link"]}`} href={`tel:${item.title}`}><FaPhoneAlt  />{item.title}</a> : null}
                  {item.name ==='email' ? <a className={`text-white ${styles["footer-link"]}`} href={`mailto:${item.title}`}><FaRegEnvelope  />{item.title}</a> : null}
                </li>
              ))}
            </ul>
          </Col>
          <Col span={8} lg={4} className={`${styles["logo"]}`}>
            <div className="text-center">
              <img src={dataFooter?.image} alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
