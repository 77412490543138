

import { notification } from "antd";
import axiosApp from "../../../../api/index.js";
import { GET_TRANSACTIONS_ERROR, GET_TRANSACTIONS_REQUEST, GET_TRANSACTIONS_SUCCESS, GET_WALLET_ERROR, GET_WALLET_REQUEST, GET_WALLET_SUCCESS, GET_WITHDRAW_ERROR, GET_WITHDRAW_REQUEST, GET_WITHDRAW_SUCCESS } from "./types.js";

export const getTransactions = ({page}) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRANSACTIONS_REQUEST,
    });
    const response = await axiosApp.get(`admin/wallet/transactions` , {
      params: {
        page:page
      },
    } );
    dispatch({
      type: GET_TRANSACTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_TRANSACTIONS_ERROR,
      payload: error.response.data.message,
    });
  }
};




export const getWallet = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_WALLET_REQUEST,
    });
    const response = await axiosApp.get(`admin/wallet/0`);
    dispatch({
      type: GET_WALLET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_WALLET_ERROR,
      payload: error.response.data.message,
    });
  }
};



export const depoistWithdraw = ({values , callback}) => async (dispatch) => {
  console.log(values)
  try {
    dispatch({
      type: GET_WITHDRAW_REQUEST,
    });
    const response = await axiosApp.post(`admin/wallet/transactions` , values, {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });
    dispatch({
      type: GET_WITHDRAW_SUCCESS,
      payload: response.data,
    });
    callback();
    notification.success({
      message: 'تم العملية بنجاح',
      duration: 2,
    })
  } catch (error) {
    dispatch({
      type: GET_WITHDRAW_ERROR,
      payload: error.response.data.message,
    });
  }
};

