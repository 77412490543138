

import { Modal, notification } from "antd";
import axiosApp from "../../../../api/index.js";
import { ACTIVATE_FIELDS_FINALLY, ACTIVATE_FIELDS_REQUEST, ACTIVATE_FIELDS_SUCCESS, CREATE_FIELDS_FINALLY, CREATE_FIELDS_REQUEST, CREATE_FIELDS_SUCCESS, DEACTIVATE_FIELDS_FINALLY, DEACTIVATE_FIELDS_REQUEST, DEACTIVATE_FIELDS_SUCCESS,  DELETE_FIELDS_FINALLY, DELETE_FIELDS_REQUEST, DELETE_FIELDS_SUCCESS, GET_COUNTRIES_ERROR, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_FIELDS_ERROR, GET_FIELDS_REQUEST, GET_FIELDS_SUCCESS } from "./types.js";

export const getCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRIES_REQUEST,
    });
    const response = await axiosApp.get(`/constants/countries` );
    dispatch({
      type: GET_COUNTRIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COUNTRIES_ERROR,
      payload: error.response.data.message,
    });
  }
};

export const getFields = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FIELDS_REQUEST,
    });
    const response = await axiosApp.get(`/admin/constants/fields` );
    dispatch({
      type: GET_FIELDS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_FIELDS_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const createFields = ({values,callback}) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_FIELDS_REQUEST,
    });
    const response = await axiosApp.post(`/admin/constants/fields` , values , 
    {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
    } );
    dispatch({
      type: CREATE_FIELDS_SUCCESS,
      payload: response.data,
    });
    notification.success({
      message: "تم اضافة المجال",
      duration: 2,
    });
    callback?.();
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما",
    });
  } finally {
    dispatch({
      type: CREATE_FIELDS_FINALLY,
    });
  }
};



export const createFieldsSub = ({values ,callback}) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_FIELDS_REQUEST,
    });
    const response = await axiosApp.post(`/admin/constants/fields` , values , 
    {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
    } );
    dispatch({
      type: CREATE_FIELDS_SUCCESS,
      payload: response.data,
    });
    notification.success({
      message: "تم اضافة المجال الفرعي",
      duration: 2,
    });
    callback?.();
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما",
    });
  } finally {
    dispatch({
      type: CREATE_FIELDS_FINALLY,
    });
  }
};




export const deleteFields = ({id,callback}) => async (dispatch) => {
  console.log("🚀 ~ file: index.js:75 ~ deleteFields ~ id:", id)
  try {
    dispatch({
      type: DELETE_FIELDS_REQUEST,
    });
    const response = await axiosApp.delete(`/admin/constants/fields`, 
    {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data:{
        id:id.toString()
      }
    });
    console.log("🚀 ~ file: index.js:82 ~ deleteFields ~ response:", response)
    notification.success({
      message: "تم حذف المستخدم",
      duration: 2,
    });
    dispatch({
      type: DELETE_FIELDS_SUCCESS,
      payload: response.data.data,
    });
    callback()
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما",
    });
  } finally {
    dispatch({
      type: DELETE_FIELDS_FINALLY,
    });
  }
};


export const deleteFieldsAll = ({selectedRows,callback}) => async (dispatch) => {
  console.log("🚀 ~ file: index.js:109 ~ deleteFieldsAll ~ selectedRows:", selectedRows)
  try {
    dispatch({
      type: DELETE_FIELDS_REQUEST,
    });
    const response = await axiosApp.delete(`/admin/constants/fields`, 
     {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data:{
        id:selectedRows.toString()
      }
    });
    dispatch({
      type: DELETE_FIELDS_SUCCESS,
      payload: response.data.data,
    });
    notification.success({
      message: "تم حذف المستخدم",
      duration: 2,
    });
    callback()
  } catch (error) {
    dispatch({
      type: DELETE_FIELDS_FINALLY,
      payload: error.response.data.message,
    });
  }
};

export const activateFields = ({id,callback}) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVATE_FIELDS_REQUEST,
    });
    const response = await axiosApp.post(`/admin/constants/fields/activate`,  {id:id.toString()} ,
     {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });
    dispatch({
      type: ACTIVATE_FIELDS_SUCCESS,
      payload: response.data.data,
    });
    notification.success({
      message: "تم تفعيل المستخدم",
      duration: 2,
    });
    callback()
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما",
    });
  } finally {
    dispatch({
      type: ACTIVATE_FIELDS_FINALLY,
    });
  }
};

export const activateFieldsAll = ({selectedRows,callback}) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVATE_FIELDS_REQUEST,
    });
    const response = await axiosApp.post(`/admin/constants/fields/activate`,  {id:selectedRows.toString()} ,
     {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });
    dispatch({
      type: ACTIVATE_FIELDS_SUCCESS,
      payload: response.data.data,
    });
    notification.success({
      message: "تم تفعيل المستخدم",
      duration: 2,
    });
    callback()
  } catch (error) {
    dispatch({
      type: ACTIVATE_FIELDS_FINALLY,
      payload: error.response.data.message,
    });
  }
};

export const deactivateFields = ({id,callback}) => async (dispatch) => {
  try {
    dispatch({
      type: DEACTIVATE_FIELDS_REQUEST,
    });
    const response = await axiosApp.post(`/admin/constants/fields/deactivate`,  {id:id.toString()} ,
     {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });
    dispatch({
      type: DEACTIVATE_FIELDS_SUCCESS,
      payload: response.data.data,
    });
    notification.success({
      message: "تم تفعيل المستخدم",
      duration: 2,
    });
    callback()
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما",
    });
  } finally {
    dispatch({
      type: DEACTIVATE_FIELDS_FINALLY,
    });
  }
};

export const deactivateFieldsAll = ({selectedRows,callback}) => async (dispatch) => {
  try {
    dispatch({
      type: DEACTIVATE_FIELDS_REQUEST,
    });
    const response = await axiosApp.post(`/admin/constants/fields/deactivate`,  {id:selectedRows.toString()} ,
     {
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });
    dispatch({
      type: DEACTIVATE_FIELDS_SUCCESS,
      payload: response.data.data,
    });
    notification.success({
      message: "تم تفعيل المستخدم",
      duration: 2,
    });
    callback()
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما",
    });
  } finally {
    dispatch({
      type: DEACTIVATE_FIELDS_FINALLY,
    });
  }
};
