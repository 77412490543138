import { CREATE_FIELDS_FINALLY, CREATE_FIELDS_REQUEST, CREATE_FIELDS_SUCCESS, GET_COUNTRIES_ERROR, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_FIELDS_ERROR, GET_FIELDS_REQUEST, GET_FIELDS_SUCCESS } from "../../actions/constants/types";

export const countries = function (state = {}, action) {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCountries: action.payload,
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//

export const fields = function (state = {}, action) {
  switch (action.type) {
    case GET_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataFields: action.payload,
      };
    case GET_FIELDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//

export const createFields = function (state = {}, action) {
  switch (action.type) {
    case CREATE_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_FIELDS_FINALLY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//
