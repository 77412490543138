import { Alert, Button, Card, Col, Form, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../components/Admin/Loading/Loading";
import { getPage , updatePage } from "../../../../store/Admin/actions/page";

const PageList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataPage, loading, error } = useSelector((state) => state.page);
  const { loading:loadingPageUpdate, error:errorPageUpdate } = useSelector((state) => state.pageUpdate);
  const { type } = useParams();

  const [data, setData] = useState();
  const [stateUpdatePage, setUpdatePage] = useState();

  useEffect(() => {
    if (type === "about") {
      setData("ABOUT");
    } else if (type === "policy") {
      setData("POLICY");
    } else if (type === "terms") {
      setData("TERMS");
    } else if (type === "cancel-policy") {
      setData("CANCELLATIONPOLICY");
    } else {
      navigate('/pageNotFound');
      // console.log(type)
    }
  }, [data , type]);

  useEffect(() => {
    if (data) {
      dispatch(getPage(data));
    }
  }, [dispatch, data , stateUpdatePage ]);


  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "تم التعديل بنجاح",
      duration: 2,
    });
  };

  const onFinish = (values) => {
    values = {
      ...values,
      id : dataPage?.data?.id,
      type : dataPage?.data?.type,
    }
    dispatch(
      updatePage
      ({
        values, 
        callback: () => {
          setUpdatePage(values)
          // dispatch(getPage(data));
          navigate(`/admin/page/${type}`);
          openNotificationWithIcon("success");
        },
      })
    );
  };

  return (
    <>
      <Card>
      {error ? <Alert type="error" message={error} /> : null}
      {errorPageUpdate ? <Alert type="error" message={errorPageUpdate} /> : null}
      {loading  ? (
        <Loading />
      ) : (
        <Row justify="center pt-20 pb-20">
          <Col span={24} lg={22}>
            <Form
              size="large"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish}
              initialValues={dataPage?.data}
            >
              <Row gutter={20} justify="center">
                <Col span={24}>
                  <Form.Item
                    name="title_ar"
                    rules={[
                      {
                        required: true,
                        message: "الحقل مطلوب",
                      },
                    ]}
                  >
                    <Input placeholder="العنوان باللغة العربية" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "الحقل مطلوب",
                      },
                    ]}
                  >
                    <Input placeholder="العنوان باللغة الانجليزية" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="data_ar"
                    rules={[
                      {
                        required: true,
                        message: "الحقل مطلوب",
                      },
                    ]}
                  >
                    <Input.TextArea rows={10} placeholder="نص المقال بالعربية" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="data"
                    rules={[
                      {
                        required: true,
                        message: "الحقل مطلوب",
                      },
                    ]}
                  >
                    <Input.TextArea rows={10} placeholder="نص المقال بالانجليزية"  />
                  </Form.Item>
                </Col>
              </Row>
              <div className="text-center">
                <Form.Item>
                  <Button loading={loadingPageUpdate} type="primary" htmlType="submit">
                    حفظ التعديلات
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      )}
      </Card>
    </>
  );
};

export default PageList;
