
// USERS
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FINALLY = 'GET_USERS_FINALLY'


export const GET_ONE_USER_REQUEST = 'GET_ONE_USER_REQUEST';
export const GET_ONE_USER_SUCCESS = 'GET_ONE_USER_SUCCESS'
export const GET_ONE_USER_FINALLY = 'GET_ONE_USER_FINALLY'


export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FINALLY = 'UPDATE_USER_FINALLY'




export const CREATE_USERS_REQUEST = 'CREATE_USERS_REQUEST';
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS'
export const CREATE_USERS_FINALLY = 'CREATE_USERS_FINALLY'


export const DELETE_USER_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USER_FINALLY = 'DELETE_USER_FINALLY'



export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USERS_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USERS_SUCCESS'
export const ACTIVATE_USER_FINALLY = 'ACTIVATE_USER_FINALLY'


export const NO_ACTIVATE_USER_REQUEST = 'NO_ACTIVATE_USERS_REQUEST';
export const NO_ACTIVATE_USER_SUCCESS = 'NO_ACTIVATE_USERS_SUCCESS'
export const NO_ACTIVATE_USER_FINALLY = 'NO_ACTIVATE_USER_FINALLY'
