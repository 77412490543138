import { Modal, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmptyData from "../../../../components/Admin/EmptyData";

import {
  FaBan,
  FaCheckCircle,
  FaRegTrashAlt,
} from "react-icons/fa";
import useAppParams from "../../../../hooks/useAppParams";
// import UsersFilter from "../../Users/Filter/index";
import { getFields , deactivateFieldsAll , deleteFields, deleteFieldsAll, deactivateFields, activateFields, activateFieldsAll } from "../../../../store/Admin/actions/constants";
import FieldsCreate from "../Create";
import FieldsSub from "../Sub";

const FieldsList = () => {
  const dispatch = useDispatch();

  const { UrlParams, handleSearch } = useAppParams({});
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { dataFields, loading } = useSelector(
    (state) => state.fields
    );
    
  useEffect(() => {
    if (UrlParams) {
      dispatch(
        getFields()
      );
    }
  }, [dispatch, UrlParams]);

  useEffect(() => {
    setData(
      dataFields?.data?.map((row) => ({
        key: row?.id,
        id: row?.id,
        name: row?.name,
        valid: row?.valid,
      }))
    );
  }, [dataFields]);

  const ChangePaginationAdv = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "الاسم",
      dataIndex: "name",
    },
    {
      title: "الحالة",
      dataIndex: "valid",
      render: (row) => (
        <div className={row === true ? 'text-success' : row === false ? 'text-danger' :null}>{row === true ? 'فعال' : row === false ? 'غير فعال' :null}</div>
      ),
    },
    {
      title: "الاجراءات",
      key: "action",
      render: (value) => (
        <Space size="middle">
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              confirm({ id: value.id, type: "single", action: "delete" });
            }}
          >
            حذف
          </button>
          <button
            className="btn btn-outline-success"
            onClick={() => {
              confirm({ id: value.id, type: "single", action: "activate" });
            }}
          >
            تفعيل
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              confirm({ id: value.id, type: "single", action: "deactivate" });
            }}
          >
            تعطيل
          </button>
          <FieldsSub id={value.id} />
        </Space>
      ),
    },
  ];


  const confirm = ({ id, type, action }) => {
    console.log("🚀 ~ file: index.jsx:150 ~ confirm ~ id:", id);
    console.log(type, action);
    if (action === "delete") {
      if (type === "single") {
        Modal.confirm({
          title: "هل تريد حذف المجال",
          okText: "تأكيد",
          cancelText: "الغاء",
          onOk: () =>
            new Promise((resolve) => {
              dispatch(
                deleteFields({
                  id,
                  callback: () => {
                    dispatch(
                      getFields()
                    );
                    setSelectedRows([selectedRows])
                    resolve();
                  },
                })
              );
            }),
        });
      } else if (type === "multible") {
        console.log(selectedRows);
        if (selectedRows?.length === 0) {
          Modal.confirm({
            title: "الرجاء تحديد مجال",
            okText: "موافق",
            cancelText: "الغاء",
          });
        } else {
          Modal.confirm({
            title: "هل تريد حذف المجال",
            okText: "تأكيد",
            cancelText: "الغاء",
            onOk: () =>
              new Promise((resolve) => {
                dispatch(
                  deleteFieldsAll({
                    selectedRows,
                    callback: () => {
                      dispatch(
                        getFields()
                      );
                      setSelectedRows([selectedRows])
                      resolve();
                    },
                  })
                );
              }),
          });
        }
      }
    } else if (action === "activate") {
      if (type === "single") {
        Modal.confirm({
          title: "هل تريد تفعيل المجال",
          okText: "تأكيد",
          cancelText: "الغاء",
          onOk: () =>
            new Promise((resolve) => {
              dispatch(
                activateFields({
                  id,
                  callback: () => {
                    console.log(rowSelection)
                    // setSelectedRows(rowSelection);
                    dispatch(
                      getFields()
                    );
                    setSelectedRows([selectedRows])
                    resolve();
                  },
                })
              );
            }),
        });
      } else if (type === "multible") {
        if (selectedRows?.length === 0) {
          Modal.confirm({
            title: "الرجاء تحديد مجال",
            okText: "موافق",
            cancelText: "الغاء",
          });
        } else {
          Modal.confirm({
            title: "هل تريد تفعيل المجال",
            okText: "تأكيد",
            cancelText: "الغاء",
            onOk: () =>
              new Promise((resolve) => {
                dispatch(
                  activateFieldsAll({
                    selectedRows,
                    callback: () => {
                      dispatch(
                        getFields()
                      );
                      setSelectedRows([selectedRows])
                      resolve();
                    },
                  })
                );
              }),
          });
        }
      }
    }else if (action === "deactivate") {
      if (type === "single") {
        Modal.confirm({
          title: "هل تريد تعطيل المجال",
          okText: "تأكيد",
          cancelText: "الغاء",
          onOk: () =>
            new Promise((resolve) => {
              dispatch(
                deactivateFields({
                  id,
                  callback: () => {
                    dispatch(
                      getFields()
                    );
                    setSelectedRows([selectedRows])
                    resolve();
                  },
                })
              );
            }),
        });
      } else if (type === "multible") {
        if (selectedRows?.length === 0) {
          Modal.confirm({
            title: "الرجاء تحديد مجال",
            okText: "موافق",
            cancelText: "الغاء",
          });
        } else {
          Modal.confirm({
            title: "هل تريد تعطيل مجال",
            okText: "تأكيد",
            cancelText: "الغاء",
            onOk: () =>
              new Promise((resolve) => {
                dispatch(
                  deactivateFieldsAll({
                    selectedRows,
                    callback: () => {
                      dispatch(
                        getFields()
                      );
                      setSelectedRows([selectedRows])
                      resolve();
                    },
                  })
                );
              }),
          });
        }
      }
    }
  };

  return (
    <>

      {/* <UsersFilter /> */}
    
      <div className="mb-15 group-btn">
      
        <FieldsCreate />
        <button
          className="btn btn-delete"
          onClick={() => {
            confirm({ type: "multible", action: "delete" });
          }}
        >
          {" "}
          <span className="icon">
            <FaRegTrashAlt />
          </span>{" "}
          حذف
        </button>
        <button
          className="btn btn-activate"
          onClick={() => {
            confirm({  type: "multible", action: "activate" });
          }}
        >
          <span className="icon">
            <FaCheckCircle />
          </span>{" "}
          تفعيل
        </button>
        <button
          className="btn btn-blocked"
          onClick={() => {
            confirm({ type: "multible", action: "deactivate" });
          }}
        >
          {" "}
          <span className="icon">
            <FaBan />
          </span>{" "}
          تعطيل
        </button>
        
      </div>
      <Table
        locale={{ emptyText: <EmptyData /> }}
        size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          current: Number(UrlParams?.page ? UrlParams?.page : 1),
          total: dataFields?.data?.total,
          onChange: (page) => {
            ChangePaginationAdv(page);
          },
        }}
      />
    </>
  );
};

export default FieldsList;
