import { Alert, Avatar, Card, Col, Typography, Row, Space, Table, Modal, Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmptyData from "../../../../components/Admin/EmptyData";
import { getTransactions, getWallet , depoistWithdraw } from "../../../../store/Admin/actions/wallet";
import user from '../../../../assets/images/user.png'
const { Title } = Typography;


const TransactionsList = () => {
  const dispatch = useDispatch();

  const [dataDepoistWallet, setDepoistWallet] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const {loading:loadingWalletWithdraw, error:errorWalletWithdraw } = useSelector((state) => state.walletWithdraw);
  const {dataTransactions, loading:loadingTransactions, error:errorTransactions } = useSelector((state) => state.transactions);
  const {dataWallet, loading, error } = useSelector((state) => state.wallet);
  const [form] = Form.useForm();
  

  useEffect(() => {
      dispatch(
        getTransactions({
          // page:page
        })
      );
  }, [dispatch , page])
  useEffect(() => {
    dispatch(
      getWallet()
    );
  }, [dispatch])
  



  useEffect(() => {
    setData(
      dataTransactions?.data?.transactions?.map((row) => ({
        key: row.id,
        id: row.id,
        name_beneficiary: row?.beneficiary?.name ,
        avatar_beneficiary: row?.beneficiary?.avatar,
        name_adviser: row?.adviser?.name,
        avatar_adviser: row?.adviser?.avatar,
        amount: row?.amount,
        state: row?.state,
        tax: row?.tax,
        walletid: row?.walletid,
      }))
    );
  }, [dataTransactions]);

 

  const ChangePagination = (number) => {
    setPage(number);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const columns = [
    {
      title: "إسم المستفيد",
      dataIndex: "name_beneficiary",
      render: (text, row) => (
        <><Avatar size={40} src={row?.avatar_beneficiary ? row?.avatar_beneficiary : user} /> {row?.name_beneficiary}</>
      ),
    },
    {
      title: "إسم المستشار",
      dataIndex: "name_adviser",
      render: (text, row) => (
        <><Avatar size={40} src={row?.avatar_beneficiary ? row?.avatar_beneficiary : user} /> {row?.name_adviser}</>
      ),
    },
    {
      title: "المبلغ",
      dataIndex: "amount",
    },
    {
      title: "الضريبة المستفادة",
      dataIndex: "tax",
    },
    {
      title: "حالة الحوالة",
      dataIndex: "state",
      render: (row) => (
        <div>{row === 0 ? <span className="text-success">الغيت</span> : row === 1 ? <span className="text-success">تمت</span> : null}</div>
      ),
    },
    
    {
      title: "الاجراءات",
      key: "action",
      render: (row) => (
        <Space size="middle">
          <button className="btn btn-outline-danger" onClick={()=>{showModal() ; setDepoistWallet({type: "2"  , walletid: row?.walletid})}}>
            سحب 
          </button>
          <button className="btn btn-outline-info" onClick={()=>{showModal() ; setDepoistWallet({type: "1" , walletid: row?.walletid})}}>
            إيداع
          </button>
          <button className="btn btn-outline-success" >
            تعطيل
          </button>
        </Space>
      ),
    },
  ];
  let locale = {
    emptyText: <EmptyData /> ,
  };


 
  const onFinish = (values) => {
    values = {
      ...values,
      type:Number(dataDepoistWallet?.type),
      walletid:dataDepoistWallet?.walletid
    }
    console.log(values)
    dispatch(
      depoistWithdraw
      ({
        values, 
        callback: () => {
          form.resetFields();
          setIsModalOpen(false);
        },
      })
    );
    
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {error||errorTransactions ? <Alert className="mb-20" message={error||errorTransactions} type="error" /> : null}
      <Row gutter={20}>
        <Col lg={4} md={6} span={24}>
          <Card className="mb-20 text-center" loading={loadingTransactions}>
            <h4 className="mb-10">المبلغ الكلي</h4>
            <Title className="mb-0" level={1}>{dataWallet?.data?.total}</Title>
          </Card>
        </Col>
        <Col lg={4} md={6} span={24}>
          <Card className="mb-20 text-center" loading={loadingTransactions}>
            <h4 className="mb-10">الأرباح</h4>
            <Title className="mb-0" level={1}>{dataWallet?.data?.available}</Title>
          </Card>
        </Col>
      </Row>
      <Table locale={{emptyText: <EmptyData />}} size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          pageSize: 10,
          total: dataTransactions?.data?.total,
          onChange: (page) => {
            ChangePagination(page);
          },
        }}
      />
      <Modal className="ModalWalet" title={false} open={isModalOpen} onCancel={handleCancel} footer={false}>

        {errorWalletWithdraw ? <Alert className="mb-10" message={errorWalletWithdraw} type="error" /> : null}

        <Form size='large' 
          labelCol={{
            span:24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={dataDepoistWallet?.type === '1' ? 'إيداع مبلغ' : dataDepoistWallet?.type === '2' ? 'سحب مبلغ' : null }
            name="amount"
            rules={[
              {
                required: true,
                message: 'الحقل مطلوب',
              },
            ]}>
            <Input placeholder='إضافة المبلغ المطلوب' />
          </Form.Item>
          <Form.Item>
            <Button loading={loadingWalletWithdraw} key="submit" htmlType="submit" type="primary">
              {dataDepoistWallet?.type === '1' ? 'إيداع' : dataDepoistWallet?.type === '2' ? 'سحب' : null}
            </Button>,
            <Button type="primary" danger  onClick={handleCancel}>
              إلغاء
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TransactionsList;
