
// WALLET
export const GET_WALLET_REQUEST = 'GET_WALLET_REQUEST';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS'
export const GET_WALLET_ERROR = 'GET_WALLET_ERROR'


// TRANSACTIONS
export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR'


// WITHDRAW
export const GET_WITHDRAW_REQUEST = 'GET_WITHDRAW_REQUEST';
export const GET_WITHDRAW_SUCCESS = 'GET_WITHDRAW_SUCCESS'
export const GET_WITHDRAW_ERROR = 'GET_WITHDRAW_ERROR'