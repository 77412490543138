import { GET_TRANSACTIONS_ERROR, GET_TRANSACTIONS_REQUEST, GET_TRANSACTIONS_SUCCESS, GET_WALLET_ERROR, GET_WALLET_REQUEST, GET_WALLET_SUCCESS, GET_WITHDRAW_ERROR, GET_WITHDRAW_REQUEST, GET_WITHDRAW_SUCCESS } from "../../actions/wallet/types";

export const transactions = function (state = {}, action) {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTransactions: action.payload,
      };
    case GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const wallet = function (state = {}, action) {
  switch (action.type) {
    case GET_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        dataWallet: action.payload,
      };
    case GET_WALLET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const walletWithdraw = function (state = {}, action) {
  switch (action.type) {
    case GET_WITHDRAW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WITHDRAW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_WITHDRAW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};