
// SECTIONS
export const SECTIONS_REQUEST = 'SECTIONS_REQUEST';
export const SECTIONS_SUCCESS = 'SECTIONS_SUCCESS'
export const SECTIONS_ERROR = 'SECTIONS_ERROR'

// BLOG
export const BLOG_REQUEST = 'BLOG_REQUEST';
export const BLOG_SUCCESS = 'BLOG_SUCCESS'
export const BLOG_ERROR = 'BLOG_ERROR'

// BLOG
export const BLOG_SINGLE_REQUEST = 'BLOG_SINGLE_REQUEST';
export const BLOG_SINGLE_SUCCESS = 'BLOG_SINGLE_SUCCESS'
export const BLOG_SINGLE_ERROR = 'BLOG_SINGLE_ERROR'