
// COUNTRIES
export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR'



// FIELDS
export const GET_FIELDS_REQUEST = 'GET_FIELDS_REQUEST';
export const GET_FIELDS_SUCCESS = 'GET_FIELDS_SUCCESS'
export const GET_FIELDS_ERROR = 'GET_FIELDS_ERROR'




// create
export const CREATE_FIELDS_REQUEST = 'CREATE_FIELDS_REQUEST';
export const CREATE_FIELDS_SUCCESS = 'CREATE_FIELDS_SUCCESS'
export const CREATE_FIELDS_FINALLY = 'CREATE_FIELDS_FINALLY'


// delete
export const DELETE_FIELDS_REQUEST = 'DELETE_FIELDS_REQUEST';
export const DELETE_FIELDS_SUCCESS = 'DELETE_FIELDS_SUCCESS'
export const DELETE_FIELDS_FINALLY = 'DELETE_FIELDS_FINALLY'


// ACTIVATE
export const ACTIVATE_FIELDS_REQUEST = 'ACTIVATE_FIELDS_REQUEST';
export const ACTIVATE_FIELDS_SUCCESS = 'ACTIVATE_FIELDS_SUCCESS'
export const ACTIVATE_FIELDS_FINALLY = 'ACTIVATE_FIELDS_FINALLY'


// deactivate
export const DEACTIVATE_FIELDS_REQUEST = 'DEACTIVATE_FIELDS_REQUEST';
export const DEACTIVATE_FIELDS_SUCCESS = 'DEACTIVATE_FIELDS_SUCCESS'
export const DEACTIVATE_FIELDS_FINALLY = 'DEACTIVATE_FIELDS_FINALLY'

