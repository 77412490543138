import { Alert, Avatar, Button, Modal, Space, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import EmptyData from "../../../../components/Admin/EmptyData";
import { deleteNotification, getNotifications ,deleteNotificationAll } from "../../../../store/Admin/actions/notifications";
import user from '../../../../assets/images/user.png'
const NotificationsLists = () => {
  const dispatch = useDispatch();


  const [notifiId, setNotifiId] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [typeDelete, setTypeDelete] = useState();
  const {dataNotifications, loading, error } = useSelector((state) => state.notifications);
  const {loading:loadingNotificationDelete, error:errorNotificationDelete } = useSelector((state) => state.notificationDelete);


  useEffect(() => {
    dispatch(
      getNotifications({
        page:page
      })
    );
  }, [dispatch , page , loadingNotificationDelete])
  


  useEffect(() => {
    setData(
      dataNotifications?.data?.notifications?.map((row) => ({
        key: row?.id,
        id: row?.id,
        name: row?.full_name,
        avatar: row?.avatar,
        role: row?.role,
        message: row?.message ,
        body: row?.body,
        date: row?.created_at,
        action: row?.id,
        
      }))
    );
  }, [dataNotifications]);

 

  const ChangePagination = (number) => {
    setPage(number);
  };

  const rowSelection = {
    onChange: (selectedRows) => {
      setSelectedRows(selectedRows)
    },
  };

  const columns = [
    {
      title: "الإسم",
      dataIndex: "name",
      render: (row) => (
        <><Avatar size={40} src={row?.avatar ? row?.avatar : user } /> {row}</>
      ),
    },
    {
      title: "عضوية المستخدم",
      dataIndex: "role",
    },
    {
      title: "عنوان الإشعار",
      dataIndex: "message",
    },
    {
      title: "نص الإشعار",
      dataIndex: "body",
    },
    {
      title: "تاريخ الإشعار",
      dataIndex: "date",
    },
    
    
    {
      title: "الاجراءات",
      key: "action",
      render: (row) => (
        <Space size="middle">
          <button className="btn btn-outline-danger" onClick={()=>{showModal(row.id);setTypeDelete('single')}}>
            حذف
          </button>
        </Space>
      ),
    },
  ];
  let locale = {
    emptyText: <EmptyData /> ,
  };
 

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'تم حذف المدونة بنجاح',
      duration: 2
    })
  }

  const showModal = async  (id) => {
    setNotifiId(id)
    setOpen(true);
    // console.log(typeDelete)
    // console.log(typeDelete)
  };
  const handleOk = async  () => {
    if(typeDelete === 'single'){
      dispatch(
        deleteNotification({
        notifiId,
        callback: () => {
          openNotificationWithIcon('success')
          setOpen(false);
        },
      })
      );
    }else if(typeDelete === 'multible'){
      dispatch(
        deleteNotificationAll({
          selectedRows,
        callback: () => {
          openNotificationWithIcon('success')
          setOpen(false);
        },
      })
      );
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };



  return (
    <>
      {error ? <Alert message={error} type="error" /> : null}
      {errorNotificationDelete ? <Alert message={errorNotificationDelete} type="error" /> : null}
      <div className="mb-15 group-btn">
        <Link className="btn btn-add" to="create"> <span className="icon"><FaPlus/></span> اضافة</Link>
        <button className="btn btn-delete" to="create"  onClick={()=>{showModal() ; setTypeDelete('multible')}}> <span className="icon"><FaRegTrashAlt/></span> حذف</button>
      </div>
      <Table locale={locale} size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          pageSize: 10,
          total: dataNotifications?.data?.total,
          onChange: (page) => {
            ChangePagination(page);
          },
        }}
      />
        <Modal
          open={open}
          title="هل تريد حذف الإشعار ؟"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              اغلاق 
            </Button>,
            <Button key="submit" type="primary" loading={loadingNotificationDelete} onClick={()=>{handleOk(notifiId);setTypeDelete('single')}}>
              حذف
            </Button>,
          ]}
        >
          {errorNotificationDelete? <Alert message={errorNotificationDelete} type="error" /> : null}
        </Modal>
    </>
  );
};

export default NotificationsLists;
